import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from "vue-axios";
import ElementPlus from "element-plus"
import {ElMessage} from "element-plus";
import 'element-plus/dist/index.css'

const app = createApp(App)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$message = ElMessage
app.use(VueAxios,axios).use(store).use(router).use(ElementPlus).mount('#app')
